<template>
	<div>
		<v-row 
        no-gutters >
			<v-col >
				<v-dialog
					v-model="responseSuccess"
					max-width="400px"
				>
					<v-card >
						<v-icon medium class='icon' :color="isError ? 'warning' : 'success'"> {{isError ? 'fa fa-exclamation-triangle' : 'fa fa-check-circle' }}</v-icon>
						
						<v-card-text class="message">{{message}}</v-card-text>
					
						<v-card-actions class="justify-center">
							<v-btn
								color="#3390FA"
								class="button"
								large
								@click="responseSuccess = false"
							>
							Entendido
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				
				<v-form  ref="form"
					v-model="valid"
				>	
					<v-text-field
						:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.required, rules.min, rules.confirmPasswordRules]"
						:type="show3 ? 'text' : 'password'"
						v-model="passwordValue"
						name="passwordValue"
						label="Nueva contraseña"
						class="input-group--focused"
						placeholder="Placeholder"
						outlined
						dense
						@click:append="show3 = !show3"
						@input="p_len"
					>
					</v-text-field>
					<password-meter :password="passwordValue" />
					<div class="lnu_container ">
						<p v-bind:class="{ lovercase_valid: contains_lovercase }">a</p>
						<p v-bind:class="{ uppercase_valid: contains_uppercase }">A</p>
						<p v-bind:class="{ number_valid: contains_number }">0-9</p>
					</div>
					<v-text-field
						type="password"
						:rules="[rules.required, rules.min, passwordConfirmationRule]"
						v-model="confirmPassword"
						name="confirmPassword"
						label="Repite nueva contraseña"
						class="input-group--focused"
						placeholder="Placeholder"
						outlined
						dense
					>
					</v-text-field>
					
			
					<v-btn
						:disabled="!valid"
						color="#3390FA"
						class="button"
						block
						tile
						@click="validate"
					>
						Confirmar
					</v-btn>
				</v-form>
				
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import passwordMeter from "vue-simple-password-meter";
	import axios from 'axios';
	
  export default {
    name: 'HelloWorld',
		components: { passwordMeter },
    data: () =>({
				passwordValue: null,
				value:null,
        valid: true,
				confirmPassword: null,
        show3: false,
        rules: {
          required: (value) => (!!value || value === 0) || 'campo requerido.',
          min: (value) => value && value.length >= 8 || 'Min 8 caracteres',
        },
        password_length: 0,
        contains_lovercase: false,
        contains_number: false,
        contains_uppercase: false,
        valid_password_length: false,
        valid_password: false,
        custom: true,
				responseSuccess: false,
				message:'',
				title:'',
				isError:false
    }),
    
		
  computed: {
			passwordConfirmationRule() {
				return () =>( this.passwordValue === this.confirmPassword) || 'Password must match'
			},
    },
    methods: {
      validate () {
        let isValidated = this.$refs.form.validate()
				if (isValidated){
					axios.post( process.env.VUE_APP_BASE_URL, {
						user_token:this.$route.query.userToken,
						password: this.passwordValue,
						password_confirmation: this.confirmPassword,
						token: this.$route.query.token
					})
					.then( ()=> {
						this.responseSuccess = true
						this.isError = false
						this.title = 'Ha sido todo un Éxito'
						this.message = 'El cambio de contraseña se ha realizado con éxito. Te enviaremos un mensaje de confirmación lo antes posible.'
						this.$refs.form.reset()
					})
					.catch(() => {
						this.responseSuccess = true
						this.isError = true
						this.title = 'Ha ocurrido un error'
						this.message = 'Lo siento, no ha sido posible cambiar la contraseña en este momento. Por favor inténtalo en unos instantes'
						this.$refs.form.reset()
						});
				}
			},
      p_len() {
        this.password_length = this.passwordValue ? this.passwordValue.length : 0;
        if (this.password_length > 8) {
          this.valid_password_length = true;
        } else {
          this.valid_password_length = false;
        }

        this.contains_lovercase = /[a-z]/.test(this.passwordValue);
        this.contains_number = /\d/.test(this.passwordValue);
        this.contains_uppercase = /[A-Z]/.test(this.passwordValue);

        // Check if the password is valid
        if (this.contains_lovercase == true && this.contains_number == true) {
          this.valid_password = false;
          if (
            this.contains_uppercase == true &&
            this.valid_password_length == true
          )
            this.valid_password = true;
        } else {
          this.valid_password = false;
        }
      }
    }
  }
</script>
<style scoped>
	.po-password-strength-bar {
		border-radius: 2px;
		transition: all 0.2s linear;
		height: 7px;
		margin-bottom: 8px;
		margin-bottom:10px;
		background:rgba(71, 87, 98, .2)
	}

	.po-password-strength-bar.risky {
		background-color: #f95e68;
	}

	.po-password-strength-bar.guessable {
		background-color: #fb964d;
	}

	.po-password-strength-bar.weak {
		background-color: #fdd244;
	}

	.po-password-strength-bar.safe {
		background-color: #b0dc53;
	}

	.po-password-strength-bar.secure {
		background-color: #35cc62;
	}
	body{
		overflow: hidden !important;
	}
	.alignment{
		padding:20px;
	}
	.v-icon.v-icon.icon{
		font-size: 40px;
		overflow: hidden;
		display: flex;
		padding: 20px;
	}
	.message{
		font-size:16px;
		color:black;
		text-align: center;
		padding:0;
		margin:0;
	}
	
	.v-dialog > .v-card > .v-card__title.title{
		font-size:20px;
		font-weight: bold;
	}
	@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
  
	.button{
		color: white !important;
		border-radius: 5px;
		overflow: hidden;
		text-align: center;
	}
  .password_length {
    padding: 2px 10px;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #FBD490;
    color: rgba(71, 87, 98, .8);
    border-radius: 4px;
    font-size: 13px;
    display: none;
    -webkit-transition: all .1s;
    transition: all .1s;
  }

  .valid_password_length {
    background: #00AD7C;
    color: rgba(255, 255, 255, .9);
  }

  .show_password_length {
    display: block;
  }

  .lnu_container {
    
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
  }

  .lnu_container p {
    width: 60px;
		padding:5px;
    font-size: 14px;
		font-weight: 700;
    text-align: center;
		border-radius:5px;
		vertical-align: bottom;
    color: rgba(71, 87, 98, .4);
    border: linear-gradient(to right, #00AD7C 50%, #eee 50%);
		border: 1px solid rgba(71, 87, 98, .2);
		margin: 10px 0;
    -webkit-transition: border .3s;
    transition: border .3s;
  }

  .lovercase_valid,
  .number_valid,
  .uppercase_valid {
		padding:5px;
    border: 1px solid#00AD7C !important;
		border-radius:5px;
    color: #00AD7C !important;
  }

  .valid_password_container {
    display: block;
    margin: 10px auto;
    border-radius: 4px;
    position: relative;
    background: #00AD7C;
    width: 20px;
    height: 20px;
    visibility: hidden;
    opacity: 0;
  }

  .show_valid_password_container {
    visibility: visible;
    opacity: 1;
  }

  .tick {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: white;
    stroke-width: 25;
    stroke-linecap: round;
    stroke-dasharray: 180;
    stroke-dashoffset: 180;
  }

  .checked {
    -webkit-animation: draw 0.5s ease forwards;
    animation: draw 0.5s ease forwards;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    } 
  } 
</style>
