<template>
  <v-app >
    <div class="alignment">
      <v-main class="align-center justify-space-around pa-10">
        <v-img
          :src="require('./assets/logo.png')"
          class="logo_chi"
          max-width="289"
          contain
        />
        <HelloWorld/>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
 
  .alignment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .logo_chi{
    margin: 20px auto;
  }
</style>
